import { useState } from "react";
import Endpoints from "../util/Endpoints";
import toast from "react-hot-toast";
import { handleFreeInputChange } from "../util/inputChange";
import Swal from "sweetalert2";

export const useCheckoutFree = (freeEventData) => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [gender, setGender] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMpesaInput, setShowMpesaInput] = useState(false);
  const [error, setError] = useState("");
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [promoError, setPromoError] = useState("");
  const [promoMessage, setPromoMessage] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const fullName = `${firstName} ${lastName}`;
  const [children, setChildren] = useState([]);
  const title = "font-roboto font-medium text-[19px] text-darkNavyBlue";
  const ptag =
    "font-roboto text-base md:text-base font-normal leading-6 md:leading-7 text-justify ml-16";
  const span = "text-darkNavyBlue text-[19px] font-semibold";

  const { SERVER_HOST, API_URL, RSVP_EVENT } = Endpoints;

  const buySingleFreeTickets = (e) => {
    e.preventDefault();
    const userData = {
      fname: firstName,
      lname: lastName,
      email: email,
      name: `${firstName} ${lastName}`,
      number: phoneNumber,
      company: company ? company : "Not Set",
      occupation: position ? position : "Not Set",
      postaladdress: postalAddress ? postalAddress : "Not Set",
      physicaladdress: physicalAddress ? physicalAddress : "Not Set",
      secondname: lastName,
      officephonenumber: officeNumber ? officeNumber : "Not Set",
      gender: gender ? gender : "Not Set",
      age: age ? age : "Not Set",
      children: [fullName],
      tinsured: false,
      order_id: "G269208",
      eventid: freeEventData?.id,
    };

    Swal.fire({
      title: "Processing RSVP",
      icon: "info",
      html: `<p class="${ptag}">Placing RSVP for <span class="${span}">${fullName}</span></p>`,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(`${SERVER_HOST}${API_URL}${RSVP_EVENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok.");
        }
        return res.json();
      })
      .then((data) => {
        let result_code = data.result_code;
        let message = data.message;       

        if (result_code === 0) {
          toast.success(
            "Event RSVP'd successfully, check your email for more details",
            {
              position: "top-right",
              duration: 4000,
              className: "mt-24 ",
            }
          );
          Swal.fire({
            icon: "success",
            iconColor: "#4CAF50",
            html: `
                  <div class='gap-4'>                    
                      <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Successfully RSVP'd this event, check your email..</p> 
                  </div>`,
            showCancelButton: false,
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "View More Events",
          }).then((result) => {
            if (result.isConfirmed) {
              //Redirect to events page
              window.location.href = "/events";
            }
          });
        } else if (result_code == 1) {
          Swal.fire({
            title: `<h4>An error occurred</h4>`,
            html: `<p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>${message}</p>`,
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "Ok",
           
            icon: "error",
          });
        }
      });
  };

  const handleFreeTicketAttendeeInput = (
    ticketIndex,
    attendeeIndex,
    type,
    value
  ) => {
    handleFreeInputChange(
      children,
      setChildren,
      ticketIndex,
      attendeeIndex,
      type,
      value
    );
  };

  const buyMultipleFreeTickets = (e) => {
    e.preventDefault();
    const userData = {
      fname: firstName,
      lname: lastName,
      email: email,
      name: `${firstName} ${lastName}`,
      number: phoneNumber,
      company: company ? company : "Not Set",
      occupation: position ? position : "Not Set",
      postaladdress: postalAddress ? postalAddress : "Not Set",
      physicaladdress: physicalAddress ? physicalAddress : "Not Set",
      secondname: lastName,
      officephonenumber: officeNumber ? officeNumber : "Not Set",
      gender: gender ? gender : "Not Set",
      age: age ? age : "Not Set",
      // children: [fullName],
      children: children,
      tinsured: false,
      order_id: "G269208",
      eventid: freeEventData?.id,
    };

    Swal.fire({
      title: "Processing RSVP",
      icon: "info",
      html: `<p class="${ptag}">Placing RSVP for <span class="${span}">${fullName}</span></p>`,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(`${SERVER_HOST}${API_URL}${RSVP_EVENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok.");
        }
        return res.json();
      })
      .then((data) => {
        let result_code = data.result_code;
        let message = data.message;

        if (result_code === 0) {
          toast.success(
            "Event RSVP'd successfully, check your email for more details",
            {
              position: "top-right",
              duration: 4000,
              className: "mt-24 ",
            }
          );
          Swal.fire({
            icon: "success",
            iconColor: "#4CAF50",
            html: `
                  <div class='gap-4'>                    
                      <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Successfully RSVP'd this event, check your email..</p> 
                  </div>`,
            showCancelButton: false,
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "View More Events",
          }).then((result) => {
            if (result.isConfirmed) {
              //Redirect to events page
              window.location.href = "/events";
            }
          });
        } else if (result_code == 1) {
          Swal.fire({
            title: `<h4 class="${title}"><span class='text-burntOrange font-roboto font-semibold text-4xl leading-10'>An error occurred</span></h4>`,

            html: `<p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>${message}</p>`,
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "Ok",
           
            icon: "error",
          });
        }
      });
  };

  return {
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    age,
    setAge,
    company,
    setCompany,
    position,
    setPosition,
    postalAddress,
    setPostalAddress,
    officeNumber,
    setOfficeNumber,
    physicalAddress,
    setPhysicalAddress,
    gender,
    setGender,
    children,
    setChildren,
    promoCode,
    setPromoCode,
    showInput,
    setShowInput,
    selectedImage,
    setSelectedImage,
    showMpesaInput,
    setShowMpesaInput,
    error,
    setError,
    mpesaNumber,
    setMpesaNumber,
    promoError,
    setPromoError,
    promoMessage,
    setPromoMessage,
    promoApplied,
    setPromoApplied,
    totalAmount,
    setTotalAmount,
    open,
    setOpen,
    buyMultipleFreeTickets,
    handleFreeTicketAttendeeInput,
    buySingleFreeTickets,
  };
};
