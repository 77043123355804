import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Endpoints from "../util/Endpoints";

const ExternalRedirect = () => {
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    let redirectUrl = "";

    if (location.pathname.startsWith("/ticket")) {
      redirectUrl = `${Endpoints.SERVER_HOST}/ticket/${id}`;
    } else if (location.pathname.startsWith("/rsvp")) {
      redirectUrl = `${Endpoints.SERVER_HOST}/rsvp/${id}/view`;
    }

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [id, location.pathname]);

  return null; 
};

export default ExternalRedirect;
