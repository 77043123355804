// import { isLocalEnvironment, isStagingEnvironment } from "./Util";

const URL = "https://client.gig.co.ke";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SERVER_HOST: URL,
  API_URL: "/api",
  LOGIN: "user/token",
  REGISTER: "user/register",
  LOGOUT: "logout",
  POPULAR_EVENTS: "/events/popular",
  UPCOMING_EVENTS: "/events/upcoming",
  MUSIC_EVENTS: "/events/music",
  WEEKEND_EVENTS: "/events/weekend",
  FEATURED_EVENTS: "/events/featured",
  ALL_EVENTS: "/events/all",
  EVENT_WITH_ID: "/event/single",
  CAROUSEL_EVENTS: "/events/featured",
  SEARCH_EVENT: "/event/search",
  RSVP_EVENT: "/event/rsvp_multiple?",
  REFERALL_CODE: "/event/referall/code/confirm",
  CONFIRM_PAYMENT: "/confirm_payment",
  MAKE_PAYMENT: "/make_payment",
  MAKE_PAYMENT_MULTIPLE: "/make_payment_multiple",
  ADD_PAYMENT: "/add_payment",
  ADD_PAYMENT_MULTIPLE: "/add_payment_multiple?",
  CARD_PAYMENT: "/make/card/payment",
  SEARCH_BY_CATEGORY: "/event/search/category",
  SEARCH_BY_LOCATION: "/event/search/location",
};
