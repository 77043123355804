import React from "react";
import { capitalize } from "../util/Util";
import PromoCodeField from "./PromoCodeField";

function MultiplePaidTicketSummary({
  eventName,
  showInput,
  promoMessage,
  promoError,
  promoCode,
  setPromoCode,
  promoApplied,
  currency,
  showHidePromoField,
  checkPromoCode,
  ticketTypeArray,
  totalAmount,
}) {
  return (
    <>
      <div className="flex flex-col p-3 gap-3">
        <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">
          Summary
        </p>
        <div className="p-4 w-full flex flex-col h-auto flex-shrink-0 rounded-xl gap-4 bg-infoGrey">
          <h4 className="font-roboto text-base font-semibold leading-6 text-primaryBlue">
            {capitalize(eventName[0])}
          </h4>
          <PromoCodeField
            showInput={showInput}
            promoMessage={promoMessage}
            promoError={promoError}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            promoApplied={promoApplied}
            checkPromoCode={checkPromoCode}
            showHidePromoField={showHidePromoField}
          />
          {ticketTypeArray
            ? ticketTypeArray.map((ticket, index) => (
                <div key={index} className="flex flex-row">
                  <p className="text-secondaryBlack font-roboto text-sm font-normal leading-[150%] flex-grow">
                    {ticket.ticketMode === "group"
                      ? ticket.totalCount / ticket.ticketModeNumber
                      : ticket.totalCount}{" "}
                    {ticket.ticketType} Ticket
                    {(ticket.ticketMode === "group"
                      ? ticket.totalCount / ticket.ticketModeNumber
                      : ticket.totalCount) > 1
                      ? "s"
                      : ""}
                  </p>
                  <p className="text-secondaryBlack font-roboto text-sm font-normal leading-[150%]">
                    {currency} {ticket.ticketPrice.toLocaleString()}
                  </p>
                </div>
              ))
            : null}
          <hr className="bg-primaryBlack h-0.5 w-full mx-auto" />
          <p className="text-primaryBlue font-roboto text-base font-semibold leading-[150%] text-right">
            Total: {currency} {totalAmount.toLocaleString()}
          </p>
        </div>
      </div>
    </>
  );
}

export default MultiplePaidTicketSummary;
