import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./hooks/auth";
import { SearchProvider } from "./components/context/useSearch";

const rootElement = document.getElementById("root");

const renderApp = () => (
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <SearchProvider>
          <App />
        </SearchProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) { 
  ReactDOM.hydrateRoot(rootElement, renderApp());
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(renderApp());
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
