import React from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const ContinueToCheckoutButton = ({ event, ticketCount, navigateTo, stateData, buttonText }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (event.eventid !== 'gev_1' && event.eventid !== 'gev_2' && event.eventpassed === 'true') {
            toast.error(
                "We're sorry, but this event has already taken place. Please check out our upcoming events",
                {
                    position: "top-right",
                    duration: 3000,
                    className: "mt-24",
                }
            );
        } else {
            navigate(navigateTo, { state: stateData });
        }
    };

    return (
        <button
            className="bg-customOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base w-full hover:bg-orangeBrown"
            onClick={handleClick}
        >
            {buttonText}
        </button>
    );
};

export default ContinueToCheckoutButton;
