import React from 'react'
import ContinueToCheckoutButton from '../components/ContinueToCheckoutButton'
import toast from 'react-hot-toast'

function CheckoutHandler({event, ticketTypeCount, ticketTypeCountArray, freeEventData, freeTicketCount}) {

  return (
   <>
    {event && event.eventpaying === "true" ? (
                        ticketTypeCount === 1 ? (
                          <ContinueToCheckoutButton
                            event={event}
                            ticketCount={ticketTypeCount}
                            navigateTo="/checkout/single"
                            stateData={ticketTypeCountArray}
                            buttonText="Continue To Check Out"
                          />
                        ) : ticketTypeCount > 1 ? (
                          <ContinueToCheckoutButton
                            event={event}
                            ticketCount={ticketTypeCount}
                            navigateTo="/checkout/multiple"
                            stateData={ticketTypeCountArray}
                            buttonText="Continue To Check Out"
                          />
                        ) : (
                          <button
                            // disabled={true}
                            className="bg-secondaryOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base w-full hover:bg-orangeBrown"
                            onClick={() =>
                              toast.error("Please select a ticket to proceed", {
                                position: "top-right",
                                duration: 3000,
                                className: "mt-24 ",
                              })
                            }
                          >
                            Continue To Check Out
                          </button>
                        )
                      ) : freeTicketCount === 1 ? (
                        <ContinueToCheckoutButton
                          event={event}
                          ticketCount={freeTicketCount}
                          navigateTo="/checkout/free/single"
                          stateData={freeEventData}
                          buttonText="Continue To RSVP"
                        />
                      ) : freeTicketCount > 1 ? (
                        <ContinueToCheckoutButton
                          event={event}
                          ticketCount={freeTicketCount}
                          navigateTo="/checkout/free/multiple"
                          stateData={freeEventData}
                          buttonText="Continue To RSVP"
                        />
                      ) : (
                        <button
                          // disabled={true}
                          className="bg-secondaryOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base w-full hover:bg-orangeBrown"
                          onClick={() =>
                            toast.error("Please select a ticket to proceed", {
                              position: "top-right",
                              duration: 3000,
                              className: "mt-24 ",
                            })
                          }
                        >
                          Continue To RSVP
                        </button>
                      )}
   </>
  )
}

export default CheckoutHandler