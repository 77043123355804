import CardSkeleton from "./CardSkeleton";
function EventRowSkeleton() {
  const renderSkeletonCards = (count) => {
    const skeletonCards = [];
    for (let i = 0; i < count; i++) {
      skeletonCards.push(<CardSkeleton key={i} />);
    }
    return skeletonCards;
  };

  return (
    <div className="flex flex-col mb-0 w-screen mx-auto md:max-w-3xl lg:max-w-7xl xl:max-w-7xl ">
      <div className="pl-4">
        <div className="mb-2 text-primaryBlue h-6 w-36 animate-pulse bg-gray-200 font-bold"></div>
      </div>
      <div className="flex flex-row overflow-x-auto max-w-sm sm:max-w-2xl mr-5 lg:max-w-7xl lg:mr-[40px] hide-scrollbar">
        {renderSkeletonCards(6)}
      </div>
    </div>
  );
}

export default EventRowSkeleton;
