import { useState } from "react";
import {
  calculateTotalAmount,
  getTicketTypeCountArray,
  updateTicketData,
} from "../util/TicketData";

export const useEventDetails = (event) => {
  const [ticketData, setTicketData] = useState({});
  const [refValue, setRefValue] = useState("");
  const [freeTicketCount, setFreeTicketCount] = useState(0);

  const handleFreeTicketCountChange = (count) => {
    setFreeTicketCount(count);
  };

  const handleTicketChange = (
    type,
    count,
    amount,
    eventID,
    ticketID,
    ticketTypeID,
    ticketMode,
    ticketModeNumber
  ) => {
    updateTicketData(
      type,
      count,
      amount,
      eventID,
      ticketID,
      ticketTypeID,
      ticketMode,
      ticketModeNumber,
      ticketData,
      setTicketData
    );
  };

  const totalAmount = calculateTotalAmount(ticketData);

  const ticketTypeCountArray = getTicketTypeCountArray(
    refValue,
    ticketData,
    event.eventname,
    event.currency,
    event.eventdataneeded
  );
 
  const ticketTypeCount = Object.keys(ticketData).length;


  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    event.eventlocation
  )}`;

  const freeEventData = {
    name: event.eventname,
    id: event.eventid,
    freeTickets: freeTicketCount,
    dataNeeded: event.eventdataneeded,
    status: event.status,
  };

  return {
    setRefValue,
    mapsUrl,
    freeTicketCount,
    handleFreeTicketCountChange,
    handleTicketChange,
    totalAmount,
    freeEventData,
    ticketTypeCountArray,
    ticketTypeCount,
  };
};
