// eslint-disable-next-line import/no-anonymous-default-export
export default {
  primary: "#EE3124",
  primaryDark: "#EE3124",
  ascent: "#CE9659",
  twitter_blue: "#1DA1F2",
  twitter_blue_light: "#00acee",
  facebook_blue: "#3b5998",
  google_red: "#CC3333",
  red_50: "#FFEBEE",
  red_100: "#FFCDD2",
  red_200: "#EF9A9A",
  red_300: "#E57373",
  red_400: "#EF5350",
  red_500: "#F44336",
  red_600: "#E53935",
  red_700: "#D32F2F",
  red_800: "#C62828",
  red_900: "#B71C1C",
  red_A100: "#FF8A80",
  red_A200: "#FF5252",
  red_A400: "#FF1744",
  red_A700: "#D50000",
  deep_purple_50: "#EDE7F6",
  deep_purple_100: "#D1C4E9",
  deep_purple_200: "#B39DDB",
  deep_purple_300: "#9575CD",
  deep_purple_400: "#7E57C2",
  deep_purple_500: "#673AB7",
  deep_purple_600: "#5E35B1",
  deep_purple_700: "#512DA8",
  deep_purple_800: "#4527A0",
  deep_purple_900: "#311B92",
  deep_purple_A100: "#B388FF",
  deep_purple_A200: "#7C4DFF",
  deep_purple_A400: "#651FFF",
  deep_purple_A700: "#6200EA",
  light_blue_50: "#E1F5FE",
  light_blue_100: "#B3E5FC",
  light_blue_200: "#81D4FA",
  light_blue_300: "#4FC3F7",
  light_blue_400: "#29B6F6",
  light_blue_500: "#03A9F4",
  light_blue_600: "#039BE5",
  light_blue_700: "#0288D1",
  light_blue_800: "#0277BD",
  light_blue_900: "#01579B",
  light_blue_A100: "#80D8FF",
  light_blue_A200: "#40C4FF",
  light_blue_A400: "#00B0FF",
  light_blue_A700: "#0091EA",
  green_50: "#E8F5E9",
  green_100: "#C8E6C9",
  green_200: "#A5D6A7",
  green_300: "#81C784",
  green_400: "#66BB6A",
  green_500: "#4CAF50",
  green_600: "#43A047",
  green_700: "#388E3C",
  green_800: "#2E7D32",
  green_900: "#1B5E20",
  green_A100: "#B9F6CA",
  green_A200: "#69F0AE",
  green_A400: "#00E676",
  green_A700: "#00C853",
  yellow_50: "#FFFDE7",
  yellow_100: "#FFF9C4",
  yellow_200: "#FFF59D",
  yellow_300: "#FFF176",
  yellow_400: "#FFEE58",
  yellow_500: "#FFEB3B",
  yellow_600: "#FDD835",
  yellow_700: "#FBC02D",
  yellow_800: "#F9A825",
  yellow_900: "#F57F17",
  yellow_A100: "#FFFF8D",
  yellow_A200: "#FFFF00",
  yellow_A400: "#FFEA00",
  yellow_A700: "#FFD600",
  deep_orange_50: "#FBE9E7",
  deep_orange_100: "#FFCCBC",
  deep_orange_200: "#FFAB91",
  deep_orange_300: "#FF8A65",
  deep_orange_400: "#FF7043",
  deep_orange_500: "#FF5722",
  deep_orange_600: "#F4511E",
  deep_orange_700: "#E64A19",
  deep_orange_800: "#D84315",
  deep_orange_900: "#BF360C",
  deep_orange_A100: "#FF9E80",
  deep_orange_A200: "#FF6E40",
  deep_orange_A400: "#FF3D00",
  deep_orange_A700: "#DD2C00",
  blue_grey_50: "#ECEFF1",
  blue_grey_100: "#CFD8DC",
  blue_grey_200: "#B0BEC5",
  blue_grey_300: "#90A4AE",
  blue_grey_400: "#78909C",
  blue_grey_500: "#607D8B",
  blue_grey_600: "#546E7A",
  blue_grey_700: "#455A64",
  blue_grey_800: "#37474F",
  blue_grey_900: "#263238",
  pink_50: "#FCE4EC",
  pink_100: "#F8BBD0",
  pink_200: "#F48FB1",
  pink_300: "#F06292",
  pink_400: "#EC407A",
  pink_500: "#E91E63",
  pink_600: "#D81B60",
  pink_700: "#C2185B",
  pink_800: "#AD1457",
  pink_900: "#880E4F",
  pink_A100: "#FF80AB",
  pink_A200: "#FF4081",
  pink_A400: "#F50057",
  pink_A700: "#C51162",
  indigo_50: "#E8EAF6",
  indigo_100: "#C5CAE9",
  indigo_200: "#9FA8DA",
  indigo_300: "#7986CB",
  indigo_400: "#5C6BC0",
  indigo_500: "#3F51B5",
  indigo_600: "#3949AB",
  indigo_700: "#303F9F",
  indigo_800: "#283593",
  indigo_900: "#1A237E",
  indigo_A100: "#8C9EFF",
  indigo_A200: "#536DFE",
  indigo_A400: "#3D5AFE",
  indigo_A700: "#304FFE",
  cyan_50: "#E0F7FA",
  cyan_100: "#B2EBF2",
  cyan_200: "#80DEEA",
  cyan_300: "#4DD0E1",
  cyan_400: "#26C6DA",
  cyan_500: "#00BCD4",
  cyan_600: "#00ACC1",
  cyan_700: "#0097A7",
  cyan_800: "#00838F",
  cyan_900: "#006064",
  cyan_A100: "#84FFFF",
  cyan_A200: "#18FFFF",
  cyan_A400: "#00E5FF",
  cyan_A700: "#00B8D4",
  light_green_50: "#F1F8E9",
  light_green_100: "#DCEDC8",
  light_green_200: "#C5E1A5",
  light_green_300: "#AED581",
  light_green_400: "#9CCC65",
  light_green_500: "#8BC34A",
  light_green_600: "#7CB342",
  light_green_700: "#689F38",
  light_green_800: "#558B2F",
  light_green_900: "#33691E",
  light_green_A100: "#CCFF90",
  light_green_A200: "#B2FF59",
  light_green_A400: "#76FF03",
  light_green_A700: "#64DD17",
  amber_50: "#FFF8E1",
  amber_100: "#FFECB3",
  amber_200: "#FFE082",
  amber_300: "#FFD54F",
  amber_400: "#FFCA28",
  amber_500: "#FFC107",
  amber_600: "#FFB300",
  amber_700: "#FFA000",
  amber_800: "#FF8F00",
  amber_900: "#FF6F00",
  amber_A100: "#FFE57F",
  amber_A200: "#FFD740",
  amber_A400: "#FFC400",
  amber_A700: "#FFAB00",
  brown_50: "#EFEBE9",
  brown_100: "#D7CCC8",
  brown_200: "#BCAAA4",
  brown_300: "#A1887F",
  brown_400: "#8D6E63",
  brown_500: "#795548",
  brown_600: "#6D4C41",
  brown_700: "#5D4037",
  brown_800: "#4E342E",
  brown_900: "#3E2723",
  purple_50: "#F3E5F5",
  purple_100: "#E1BEE7",
  purple_200: "#CE93D8",
  purple_300: "#BA68C8",
  purple_400: "#AB47BC",
  purple_500: "#9C27B0",
  purple_600: "#8E24AA",
  purple_700: "#7B1FA2",
  purple_800: "#6A1B9A",
  purple_900: "#4A148C",
  purple_A100: "#EA80FC",
  purple_A200: "#E040FB",
  purple_A400: "#D500F9",
  purple_A700: "#AA00FF",
  blue_50: "#E3F2FD",
  blue_100: "#BBDEFB",
  blue_200: "#90CAF9",
  blue_300: "#64B5F6",
  blue_400: "#42A5F5",
  blue_500: "#2196F3",
  blue_600: "#1E88E5",
  blue_700: "#1976D2",
  blue_800: "#1565C0",
  blue_900: "#0D47A1",
  blue_A100: "#82B1FF",
  blue_A200: "#448AFF",
  blue_A400: "#2979FF",
  blue_A700: "#2962FF",
  teal_50: "#E0F2F1",
  teal_100: "#B2DFDB",
  teal_200: "#80CBC4",
  teal_300: "#4DB6AC",
  teal_400: "#26A69A",
  teal_500: "#009688",
  teal_600: "#00897B",
  teal_700: "#00796B",
  teal_800: "#00695C",
  teal_900: "#004D40",
  teal_A100: "#A7FFEB",
  teal_A200: "#64FFDA",
  teal_A400: "#1DE9B6",
  teal_A700: "#00BFA5",
  lime_50: "#F9FBE7",
  lime_100: "#F0F4C3",
  lime_200: "#E6EE9C",
  lime_300: "#DCE775",
  lime_400: "#D4E157",
  lime_500: "#CDDC39",
  lime_600: "#C0CA33",
  lime_700: "#AFB42B",
  lime_800: "#9E9D24",
  lime_900: "#827717",
  lime_A100: "#F4FF81",
  lime_A200: "#EEFF41",
  lime_A400: "#C6FF00",
  lime_A700: "#AEEA00",
  orange_50: "#FFF3E0",
  orange_100: "#FFE0B2",
  orange_200: "#FFCC80",
  orange_300: "#FFB74D",
  orange_400: "#FFA726",
  orange_500: "#FF9800",
  orange_600: "#FB8C00",
  orange_700: "#F57C00",
  orange_800: "#EF6C00",
  orange_900: "#E65100",
  orange_A100: "#FFD180",
  orange_A200: "#FFAB40",
  orange_A400: "#FF9100",
  orange_A700: "#FF6D00",
  grey_50: "#FAFAFA",
  grey_100: "#F5F5F5",
  grey_200: "#EEEEEE",
  grey_300: "#E0E0E0",
  grey_400: "#BDBDBD",
  grey_500: "#9E9E9E",
  grey_600: "#757575",
  grey_700: "#616161",
  grey_800: "#424242",
  grey_900: "#212121",
  black: "#000000",
  white: "#FFFFFF",
  barclaysBlue: "#00afe9",
  view_line: "#9f9e9e",
  View_line: "#EAEBED",
  transparent: "#00000000",
  sort_by_bg: "#74f6f6f6",
  activity_bg: "#f6f6f6",
  circular_pic_border: "#5f707070",
  resen_gray: "#818181",
  edit_phn: "#a62c8d",
  light_weight_gray: "#99000000",
  griffin_grey: "#7587A1",
  griffin_green: "#19A598",
  pale_blue: "#EAEBF2",
  lavendar_blue: "#BDC0D8",
  light_peach: "#F8CFBB",
  pastel_green: "#C8E6C9",
  pale_yellow: "#FEE9B6",
};
