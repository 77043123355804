import React from "react";
import EventSummary from "../pages/Events/EventSummary";

function AboutEventHandler({ event }) {
  return (
    <>
      {event && event.eventpaying === "true" ? (
        <div className="flex flex-col space-y-2 lg:w-11/12 xl:w-full mt-2 p-5 ">
          <div className="flex flex-col lg:flex md:flex-col md:space-y-2 ">
            <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base">
              About Event
            </h3>
            <EventSummary event={event} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2 lg:w-11/12 xl:w-full mt-6 p-2 ">
          <div className="flex flex-col md:space-y-2 ">
            <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base">
              About Event
            </h3>
            <EventSummary event={event} />
          </div>
        </div>
      )}
    </>
  );
}

export default AboutEventHandler;
