import React from "react";

function EventOrganizer() {
  return (
    <>
      <div className="container">
        <div className="zpl-32 pt-12">
          <div className="bg-mutedBeige container relative sm:items-stretch sm:justify-start  mx-auto max-w-7xl h-[1000px] flex-shrink-0 md:py-16 rounded-3xl">
            <div className="flex flex-col items-center justify-items-center gap-4">
              <div className="flex flex-col gap-4 text-center items-center">
                <h2 className="text-primary-black-400 font-roboto text-5xl font-medium leading-relaxed">
                  Are you an{" "}
                  <span className="text-cinnamon">Event Organizer?</span>
                </h2>
                <h2 className="font-roboto text-5xl font-medium leading-normal">
                  <span className="text-deepBlue">Gig</span> is your go to
                  affordable and easy <br /> event ticketing and checkin system
                </h2>
                <button className="w-56 h-14 bg-customOrange text-white font-bold py-2 px-6 text-base rounded-lg">
                  Create An Event
                </button>
                <h2 className="font-roboto text-[28px] font-medium leading-relaxed">
                  Event organizers choose our event ticketing platform to:
                </h2>
              </div>
              <div>
                <div>
                  <div className="w-72 h-56 flex-shrink-0 max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-200 m-5 mb-30  ml-10 transition duration-500"></div>
                  <div className="w-72 h-56 flex-shrink-0 max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-200 m-5 mb-30  ml-10 transition duration-500"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventOrganizer;
