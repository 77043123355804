import React from 'react';

const TextInput = ({ label, id, value, onChange, type = 'text', required = false, placeholder = '' }) => (
  <div className="flex flex-col">
    <label htmlFor={id} className="text-primaryBlack font-roboto text-sm lg:text-base font-normal leading-6">
      {label}{required && <span className="text-secondaryRed">*</span>}
    </label>
    <input
      required={required}
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"
      placeholder={placeholder}
    />
  </div>
);

export default TextInput;
