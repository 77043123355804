import React from "react";
import PromoCodeButton from "./PromoCodeButton";
import PromoCodeInput from "./PromoCodeInput";

function PromoCodeField({
  showInput,
  promoMessage,
  promoError,
  promoCode,
  setPromoCode,
  promoApplied,
  showHidePromoField,
  checkPromoCode,
}) {
  return (
    <>
      <h4
        className={`font-roboto text-sm font-semibold leading-6 ${
          showInput ? "text-primaryBlue" : "text-secondaryRed"
        }  underline cursor-pointer`}
        onClick={(e) => {
          e.stopPropagation();
          showHidePromoField();
        }}
      >
        Add Promo Code?
      </h4>

      {showInput && (
        <div className="flex flex-col">
          <div>
            {promoMessage || promoError ? (
              <p
                className={`font-roboto font-normal text-sm leading-[150%] ${
                  promoError ? "text-rose-700" : "text-green-500"
                } `}
              >
                {promoError || promoMessage}
              </p>
            ) : null}
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-4/5">
              <PromoCodeInput
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoApplied={promoApplied}
              />
            </div>
            <div className="w-1/5">
              {promoApplied ? null : (
                <PromoCodeButton
                  checkPromoCode={checkPromoCode}
                  promoCode={promoCode}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PromoCodeField;
