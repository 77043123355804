import React from 'react'

function PromoCodeButton({checkPromoCode, promoCode}) {
  return (
    <button
                    className="bg-customOrange hover:bg-orangeBrown text-white hover:text-white font-bold  rounded-lg text-base w-full p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      checkPromoCode(promoCode, false);
                    }}                   
                  >
                    Apply
                  </button>
  )
}

export default PromoCodeButton