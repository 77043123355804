import React from 'react';

const GenderInput = ({ gender, setGender }) => (
  <div className="flex flex-col">
    <p className="text-secondaryBlack font-roboto text-sm lg:text-base font-regular leading-6">Gender</p>
    <div className="flex flex-row gap-4">
      <div
        onClick={() => setGender("Male")}
        className={
          gender === "Male"
            ? "w-full flex items-center justify-center rounded-lg cursor-pointer text-secondaryBlue text-md p-2 font-roboto bg-lavendarBlue"
            : "w-full flex items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-primaryBlack text-md p-2 font-roboto hover:text-secondaryBlue hover:border-secondaryBlue"
        }
      >
        <span>Male</span>
      </div>
      <div
        onClick={() => setGender("Female")}
        className={
          gender === "Female"
            ? "w-full flex items-center justify-center rounded-lg cursor-pointer text-secondaryBlue text-md p-2 font-roboto bg-lightPastel"
            : "w-full flex items-center justify-center border rounded-lg cursor-pointer border-primaryBlack text-primaryBlack text-md p-2 font-roboto hover:text-secondaryBlue hover:border-secondaryBlue"
        }
      >
        <span>Female</span>
      </div>
    </div>
  </div>
);

export default GenderInput;
