import React, { useEffect, useState } from "react";
import Endpoints from "../../util/Endpoints";
import useDebounce from "../../hooks/useDebounce";

const SearchContext = React.createContext();

const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  const [searchedEvents, setSearchedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedQuery = useDebounce(search, 500);

  const { SERVER_HOST, API_URL, SEARCH_EVENT } = Endpoints;

  const baseEndpoint = `${SERVER_HOST}${API_URL}`;

  useEffect(() => {
    const fetchSearchedEvents = async () => {
      if (!debouncedQuery) return;

      setIsLoading(true);
      try {
        const events = await fetchEventsByCriteria(
          SEARCH_EVENT,
          debouncedQuery
        );
        setSearchedEvents(events);
      } catch (error) {
        console.error("Error fetching searched events:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSearchedEvents();
  }, [debouncedQuery]);

  const fetchEventsByCriteria = async (endpoint, searchTerm) => {
    try {
      const response = await fetch(`${baseEndpoint}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventname: searchTerm }),
      });
      const searchData = await response.json();

      return searchData.data;
    } catch (error) {
      console.error("Error searching events:", error);
      return [];
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClear = () => {
    setSearch("");
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        searchedEvents,
        isLoading,
        debouncedQuery,
        setSearchedEvents,
        handleSearch,
        handleClear,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
