export const handlePaidInputChange = (
  children,
  setChildren,
  ticketIndex,
  attendeeIndex,
  type,
  value
) => {
  setChildren((prevChildren) => {
    const updatedChildren = [...prevChildren];

    // Initialize the ticket array if it doesn't exist
    if (!updatedChildren[ticketIndex]) {
      updatedChildren[ticketIndex] = [];
    }

    // Initialize the attendee object if it doesn't exist
    if (!updatedChildren[ticketIndex][attendeeIndex]) {
      updatedChildren[ticketIndex][attendeeIndex] = {
        firstName: "",
        lastName: "",
      };
    }

    // Update the specific type (firstName or lastName) with the new value
    updatedChildren[ticketIndex][attendeeIndex][type] = value;

    // Optionally, concatenate firstName and lastName into a single string
    const { firstName, lastName } = updatedChildren[ticketIndex][attendeeIndex];
    updatedChildren[ticketIndex][attendeeIndex].fullName = `${firstName} ${lastName}`;

    return updatedChildren;
  });
};


export const handleFreeInputChange = (
  children,
  setChildren,
  attendeeIndex,
  type,
  value
) => {
  let updatedChildren = [...children];


  if (!Array.isArray(updatedChildren)) {
    updatedChildren = [];
  }
  
  if (typeof updatedChildren[attendeeIndex] === 'string') {
    const [firstName, lastName] = updatedChildren[attendeeIndex].split(' ');
    updatedChildren[attendeeIndex] = { firstName, lastName };
  }

 
  if (!updatedChildren[attendeeIndex] || typeof updatedChildren[attendeeIndex] !== 'object') {
    updatedChildren[attendeeIndex] = {
      firstName: "",
      lastName: "",
    };
  }
 
  updatedChildren[attendeeIndex][type] = value;

  const { firstName, lastName } = updatedChildren[attendeeIndex];

  
  if (firstName && lastName) {
    updatedChildren[attendeeIndex] = `${firstName} ${lastName}`;
  }

 
  setChildren(updatedChildren);
};

export const handleSingleGroupInputChange = (children, setChildren, ticketIndex, type, value) => {
  setChildren((prevChildren) => {
    const newChildren = [...prevChildren];
    if (!newChildren[ticketIndex]) {
      newChildren[ticketIndex] = { firstName: "", lastName: "" }; // Initialize both fields
    }
    newChildren[ticketIndex][type] = value;
    return newChildren;
  });
};


// export const handleSingleGroupInputChange = (
//   children,
//   setChildren,
//   attendeeIndex,
//   type,
//   value
// ) => {
//   let updatedChildren = [...children];

//   if (!updatedChildren[attendeeIndex]) {
//     updatedChildren[attendeeIndex] = {
//       firstName: "",
//       lastName: "",
//     };
//   }

//   // Update the specific field (either firstName or lastName)
//   updatedChildren[attendeeIndex] = {
//     ...updatedChildren[attendeeIndex],
//     [type]: value,
//   };

//   // Update the array with the new full names
//   setChildren(updatedChildren.map(child => {
//     const firstName = child.firstName || '';
//     const lastName = child.lastName || '';
//     return `${firstName} ${lastName}`.trim();
//   }));
// };



