import toast from "react-hot-toast";
import Swal from "sweetalert2";
import $ from "jquery";
import Endpoints from "../util/Endpoints";
const { SERVER_HOST, API_URL, ADD_PAYMENT, CARD_PAYMENT } = Endpoints;

export const confirmPaymentPesapal = (userData) => {
  const title = "font-roboto font-medium text-[19px] text-darkNavyBlue";
  const text = "flex flex-col wrap";
  const ptag =
    "font-roboto text-base md:text-base font-normal leading-6 md:leading-7 text-justify";
  // const ptagpaybill = 'font-roboto text-base md:text-base font-normal leading-6 md:leading-7 text-justify ml-6';
  const span = "text-darkNavyBlue text-[19px] font-semibold";

  const newTicketAmount = Math.round(parseFloat(userData.ticketCost) * 1.035);

  Swal.fire({
    title: '<h4 class="' + title + '">Confirm Extra Card Charges</h4>',
    html: `    
                <div class="${text}">
                    <p class="${ptag}">Please note that card payments have a <span class='${span}'>3.5%</span> extra charge for processing. Total amount charged will be <span class='${span}'>${userData.currency} ${newTicketAmount}</span> for <span class='${span}'>${
      userData.ticketnumber
    }</span>  ticket${userData.ticketnumber > 1 ? "s" : ""}</p>    
                </div>
            `,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    cancelButtonColor: "#EF4444",
    confirmButtonColor: "#4CAF50",
    confirmButtonText: "Proceed with payment",
    reverseButtons: true,
   
    icon: "info",
    customClass: {
      actions: "gap-6",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      toast.loading("Making Payment Request", {
        position: "top-right",
        duration: 3000,
        className: "mt-24 ",
      });
      // Proceed with the payment
      Swal.fire({
        title: `<h4 class="${title}">Requesting Payment </h4>`,
        html: `
                <div>
                <span class='text-burntOrange font-roboto font-semibold text-4xl leading-10'>${userData.currency} ${newTicketAmount}</span>
                <p class='font-roboto text-base md:text-base font-normal leading-6 md:leading-7'>Wait for the payment options and select the card option....</p>
                </div>`,
        confirmButtonColor: "#4CAF50",
        confirmButtonText: "Ok",
       
        icon: "info",
      });
      makePayment(userData);
    }
  });

  const makePayment = (data) => {
    const dataString =
      `transaction_id=${data.transactionId}` +
      `&transaction_phone=${data.userNumber}` +
      `&transaction_amount=${newTicketAmount}` +
      `&transaction_ref_type=${data.transactionRefType}` +
      `&transaction_ref_number=${data.transactionRefNumber}` +
      `&transaction_ref_id=${data.eventId}` +
      `&transaction_status=${data.transactionStatus}` +
      `&transaction_type=${data.transactionType}` +
      `&transaction_for=${data.transactionFor}` +
      `&transaction_data=${JSON.stringify(data.transactionData)}` + // Assuming transactionData is an object
      `&transaction_date_time=${data.transactionDateTime}` +
      `&transaction_date=${data.transactionDate}` +
      `&transaction_time=${data.transactionTime}` +
      `&transaction_referall=${data.transactionReferallCode}` +
      `&tickettransactionreftype=${data.transactionRefType}`;

    $.ajax({
      url: `${SERVER_HOST}${API_URL}${ADD_PAYMENT}`,
      method: "POST",
      // contentType: "application/json",
      data: dataString,
      cache: false,
      success: function (response) {
        let result_code = response.result_code;

        if (result_code === 0) {
          makePaymentPesaPal(data);
        } else {
          Swal.fire({
            title: `<h4 class="${title}">Reload</h4>`,
            html: (
              <p class="font-roboto text-base md:text-base font-normal leading-6 md:leading-7">
                Refresh the page
              </p>
            ),
            confirmButtonColor: "#4CAF50",
            confirmButtonText: "Ok",
           
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      },
      error: function (xhr, status, error) {
        Swal.fire({
          title: `<h4 class="${title}">Reload</h4>`,
          html: (
            <p class="font-roboto text-base md:text-base font-normal leading-6 md:leading-7">
              Refresh the page
            </p>
          ),
          confirmButtonColor: "#4CAF50",
          confirmButtonText: "Ok",
         
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      },
    });
  };

  function makePaymentPesaPal(data) {
    const {
      transactionId,
      userNumber,
      transactionData,
      ticketDescription,
      currency,
    } = data;

    const { ticketusername, ticketuseremail } = transactionData;

    const dataString = `transaction_id=${transactionId}&user_number=${userNumber}&user_name=${ticketusername}&user_email=${ticketuseremail}&description=${ticketDescription}&currency=${currency}&amount=${newTicketAmount}`;

    $.ajax({
      url: `${SERVER_HOST}${API_URL}${CARD_PAYMENT}`,
      method: "POST",
      // contentType: "application/json",
      data: dataString,
      cache: false,
      success: function (response) {
        let data = response.data;
        let result_code = response.result_code;
        if (result_code === 0) {
          window.location.href = data;
        } else {
          toast.error(
            "Failed. Could not initiate payment. Try again and please contact admin if the issue continues.",
            {
              position: "top-right",
              duration: 3000,
              className: "mt-24 ",
            }
          );
        }
      },
      error: function (xhr, status, error) {
        toast.error(
          "Error returning receipt, don't worry. Please contact the admin",
          {
            position: "top-right",
            duration: 3000,
            className: "mt-24 ",
          }
        );
      },
    });
  }
};
