import React from "react";

function FreeAttendeeForm({ freeEventData, handleFreeTicketAttendeeInput }) {
  return (
    <>
      {[...Array(freeEventData.freeTickets * 2)].map((_, attendeeIndex) =>
        attendeeIndex % 2 === 0 ? (
          <div key={attendeeIndex} className="flex flex-col ">
            <label
              htmlFor={`first_name_${freeEventData.id}_${attendeeIndex / 2}`}
              className="text-primaryBlack font-roboto text-base font-normal leading-6"
            >
              First Name
              <span className="text-secondaryRed">*</span>
            </label>
            <input
              required
              type="text"
              id={`first_name_${freeEventData.id}_${attendeeIndex / 2}`}
              className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"
              placeholder="John"
              onChange={(e) =>
                handleFreeTicketAttendeeInput(
                  attendeeIndex / 2,
                  "firstName",
                  e.target.value
                )
              }
            />
          </div>
        ) : (
          <div key={attendeeIndex} className="flex flex-col ">
            <label
              htmlFor={`last_name_${freeEventData.id}_${
                (attendeeIndex - 1) / 2
              }`}
              className="text-primaryBlack font-roboto text-base font-normal leading-6"
            >
              Last Name
              <span className="text-secondaryRed">*</span>
            </label>
            <input
              required
              type="text"
              id={`last_name_${freeEventData.id}_${(attendeeIndex - 1) / 2}`}
              className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"
              placeholder="Doe"
              onChange={(e) =>
                handleFreeTicketAttendeeInput(
                  (attendeeIndex - 1) / 2,
                  "lastName",
                  e.target.value
                )
              }
            />
          </div>
        )
      )}
    </>
  );
}

export default FreeAttendeeForm;
