import { useState, useEffect } from "react";
import Endpoints from "../util/Endpoints";

export const useEvents = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [popularEvents, setPopularEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [musicEvents, setMusicEvents] = useState([]);
  const [weekendEvents, setWeekendEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [carouselEvents, setCarouselEvents] = useState([]);
  const [event, setEvent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const {
    SERVER_HOST,
    API_URL,
    ALL_EVENTS,
    UPCOMING_EVENTS,
    POPULAR_EVENTS,
    MUSIC_EVENTS,
    WEEKEND_EVENTS,
    FEATURED_EVENTS,
    CAROUSEL_EVENTS,
    EVENT_WITH_ID,
  } = Endpoints;

  const baseEndpoint = `${SERVER_HOST}${API_URL}`;

  useEffect(() => {
    const fetchAllEvents = async () => {
      try {
        setIsLoading(true);

        const allEventsPromise = fetchEvents(`${baseEndpoint}${ALL_EVENTS}`);
        const upcomingEventsPromise = fetchEvents(
          `${baseEndpoint}${UPCOMING_EVENTS}`
        );
        const popularEventsPromise = fetchEvents(
          `${baseEndpoint}${POPULAR_EVENTS}`
        );
        const musicEventsPromise = fetchEvents(
          `${baseEndpoint}${MUSIC_EVENTS}`
        );
        const weekendEventsPromise = fetchEvents(
          `${baseEndpoint}${WEEKEND_EVENTS}`
        );
        const featuredEventsPromise = fetchEvents(
          `${baseEndpoint}${FEATURED_EVENTS}`
        );
        const carouselEventsPromise = fetchEvents(
          `${baseEndpoint}${CAROUSEL_EVENTS}`
        );

        const allEvents = await allEventsPromise;
        const upcomingEvents = await upcomingEventsPromise;
        const popularEvents = await popularEventsPromise;
        const musicEvents = await musicEventsPromise;
        const weekendEvents = await weekendEventsPromise;
        const featuredEvents = await featuredEventsPromise;
        const carouselEvents = await carouselEventsPromise;

        setAllEvents(allEvents);
        setUpcomingEvents(upcomingEvents);
        setPopularEvents(popularEvents);
        setMusicEvents(musicEvents);
        setWeekendEvents(weekendEvents);
        setFeaturedEvents(featuredEvents);
        setCarouselEvents(carouselEvents);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setIsLoading(false);
      }
    };

    fetchAllEvents();
  }, []);

  const fetchEvents = async (url) => {
    try {
      const cachedData = JSON.parse(localStorage.getItem(url));
      const lastFetchTime = localStorage.getItem(url + "_lastFetchTime");

      // Check if cached data is less than 10 minutes old
      const isDataValid =
        cachedData &&
        lastFetchTime &&
        Date.now() - lastFetchTime < 10 * 60 * 1000;

      if (isDataValid) {
        return cachedData;
      } else {
        const response = await fetch(url);
        const events = await response.json();

        // Cache fetched data
        localStorage.setItem(url, JSON.stringify(events.data));
        localStorage.setItem(url + "_lastFetchTime", Date.now());

        return events.data;
      }
    } catch (error) {
      throw new Error("Error fetching events:", error);
    }
  };

  const loadEvent = async (id) => {
    try {
      const cachedEvent = JSON.parse(localStorage.getItem(`event_${id}`));
      const lastFetchTime = localStorage.getItem(`event_${id}_lastFetchTime`);

      const isDataValid =
        cachedEvent &&
        lastFetchTime &&
        Date.now() - lastFetchTime < 10 * 60 * 1000;

      if (isDataValid) {
        setEvent(cachedEvent);
      } else {
        const response = await fetch(`${baseEndpoint}${EVENT_WITH_ID}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ eventid: id }),
        });
        const eventData = await response.json();

        if (eventData && eventData.data) {
          setEvent(eventData.data);

          localStorage.setItem(`event_${id}`, JSON.stringify(eventData.data));
          localStorage.setItem(`event_${id}_lastFetchTime`, Date.now());
        } else {
          console.error("No data found in the API response.");
        }
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const handleEventClick = (id) => {
    const previousEventId = localStorage.getItem("current_event_id");
    if (previousEventId && previousEventId !== id) {
      localStorage.removeItem(`event_${previousEventId}`);
      localStorage.removeItem(`event_${previousEventId}_lastFetchTime`);
    }
    localStorage.setItem("current_event_id", id);

    loadEvent(id);
  }; 

  return {
    allEvents,
    popularEvents,
    upcomingEvents,
    musicEvents,
    weekendEvents,
    featuredEvents,
    loadEvent,
    event,
    carouselEvents,
    isLoading,
    handleEventClick
  };
};
