import React from "react";
import { capitalize } from "../util/Util";
import PromoCodeField from "./PromoCodeField";

function SinglePaidTicketSummary({
  eventName,
  showInput,
  promoMessage,
  promoError,
  promoCode,
  setPromoCode,
  promoApplied,
  ticketCount,
  ticketType,
  currency,
  totalTicketPrice,
  showHidePromoField,
  checkPromoCode,
  ticketModeNumber,
  ticketMode
}) {
 
    const displayCount = ticketMode === 'group' ? ticketCount / ticketModeNumber : ticketCount;
    const plural = displayCount > 1 ? "s" : "";
 
  return (
    <>
      <div className="flex flex-col p-3 gap-3">
        <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">
          Summary
        </p>
        <div className="p-4 w-full flex flex-col h-auto flex-shrink-0 rounded-xl gap-4 bg-infoGrey">
          <h4 className="font-roboto text-base font-semibold leading-6 text-primaryBlue">
            {capitalize(eventName)}
          </h4>
          <PromoCodeField
            showInput={showInput}
            promoMessage={promoMessage}
            promoError={promoError}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            promoApplied={promoApplied}
            checkPromoCode={checkPromoCode}
            showHidePromoField={showHidePromoField}
          />
          <div className="flex flex-row ">
            <p className="text-secondaryBlack font-roboto text-sm font-normal leading-[150%] flex-grow">
            {displayCount} x {ticketType}
            {plural}
            </p>
            <p className="text-secondaryBlack font-roboto text-sm font-normal leading-[150%]">
              {currency} {totalTicketPrice.toLocaleString()}
            </p>
          </div>

          <hr className="bg-primaryBlack h-0.5 w-full mx-auto" />
          <p className="text-primaryBlue font-roboto text-base font-semibold leading-[150%] text-right">
            Total: {currency} {totalTicketPrice.toLocaleString()}
          </p>
        </div>
      </div>
    </>
  );
}

export default SinglePaidTicketSummary;
