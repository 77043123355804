import Signup from "../pages/Authentication/Signup";
import Login from "../pages/Authentication/Login";
import AllEvents from "../pages/Events/AllEvents";
import EventDetails from "../pages/Events/EventDetails";
import EventCheckoutSingle from "../pages/Events/EventCheckoutSingle";
import EventCheckoutMultiple from "../pages/Events/EventCheckoutMultiple";
import Carousel from "../components/Carousel";
import EventOrganizer from "../pages/EventOrganizer/EventOrganizer";
import EventCheckoutFreeMultiple from "../pages/Events/EventCheckoutFreeMultiple";
import EventCheckoutFreeSingle from "../pages/Events/EventCheckoutFreeSingle";
import ExternalRedirect from "../components/ExternalRedirect";
import EventRedirect from "../components/EventRedirect";


const authRoutes = [
  { path: "/signup", component: Signup },
  { path: "/login", component: Login },
];

const userRoutes = [
  { path: "/event-organizer", component: EventOrganizer },];


const userNonAuthRoutes = [
  { path: "/events", component: AllEvents },
  { path: "/event/:id/ticket", component: EventDetails },
  { path: "/checkout/single", component: EventCheckoutSingle },
  { path: "/checkout/multiple", component: EventCheckoutMultiple },
  { path: "/checkout/free/single", component: EventCheckoutFreeSingle },
  { path: "/checkout/free/multiple", component: EventCheckoutFreeMultiple },
  { path: "/carousel", component: Carousel },
  { path: "/ticket/:id", exact: true, component: () => <ExternalRedirect /> },
  { path: "/rsvp/:id", exact: true, component: () => <ExternalRedirect /> },
  { path: "/events/:id", exact: true, component: EventRedirect }, 
];

export { authRoutes, userRoutes, userNonAuthRoutes };
