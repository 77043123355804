import React from "react";
import { capitalize } from "../util/Util";

const FreeTicketSummary = ({ eventName, freeTickets, currency }) => (
  <div className="flex flex-col p-3 gap-3">
    <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">
      Summary
    </p>
    <div className="p-4 w-full flex flex-col h-auto flex-shrink-0 rounded-xl gap-4 bg-infoGrey">
      <h4 className="font-roboto text-md font-semibold leading-6 text-primaryBlue">
        {capitalize(eventName)}
      </h4>
      <div className="flex flex-row">
        <p className="text-secondaryBlack font-roboto text-sm font-normal leading-[150%] flex-grow">
          {freeTickets} x Free Ticket{freeTickets > 1 ? "s" : ""}
        </p>
        <p className="text-secondaryBlack font-roboto text-sm font-normal leading-[150%]">
          {currency} 0
        </p>
      </div>
      <hr className="bg-primaryBlack h-0.5 w-full mx-auto" />
      <p className="text-primaryBlue font-roboto text-base font-semibold leading-[150%] text-right">
        Total: {currency} 0
      </p>
    </div>
  </div>
);

export default FreeTicketSummary;
