import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  generateRandomString,
  getCurrentDate,
  getCurrentTime,
} from "../util/Util";
import Endpoints from "../util/Endpoints";
import { confirmInfo } from "../payments/Payment";
import { confirmPaymentPesapal } from "../payments/PaymentVisa";
import { handleSingleGroupInputChange } from "../util/inputChange";

export const useCheckoutSingle = (ticketTypeArray) => {
  const {
    totalCount: ticketCount,
    ticketType,
    eventName,
    currency,
    eventID,
    ticketID,
    ticketTypeID,
    dataNeeded,
    ticketMode,
    ticketModeNumber
  } = ticketTypeArray[0];

  const [showInput, setShowInput] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMpesaInput, setShowMpesaInput] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [gender, setGender] = useState("");
  const fullName = `${firstName} ${lastName}`;
  const [children, setChildren] = useState([]);
  const [error, setError] = useState("");
  const [promoMessage, setPromoMessage] = useState("");
  const [promoError, setPromoError] = useState("");
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [totalTicketPrice, setTotalTicketPrice] = useState(
    ticketTypeArray[0].totalPrice
  );

  useEffect(() => {
    setMpesaNumber(phoneNumber)
  }, [phoneNumber])

  const repeatFullName = (count) =>
    Array.from({ length: count }, () => fullName);

  const { SERVER_HOST, API_URL, REFERALL_CODE } = Endpoints;

  const showHidePromoField = (e) => {
    setShowInput(!showInput);
  };

  const mpesaVisaSelection = (image) => {
    if (image === selectedImage) {
      setSelectedImage(null);
      setShowMpesaInput(false);
    } else {
      setSelectedImage(image);
      setShowMpesaInput(image === "mpesa");
    }
  };

  useEffect(() => {
    if (ticketTypeArray && ticketTypeArray.length > 0) {
      const refValue = ticketTypeArray[0].refValue;
      if (refValue && refValue.trim() !== "" && promoApplied === false) {
        checkPromoCode(refValue, true);
      }
    }
  }, []);

  const checkPromoCode = (promoCodeInput, isAutomatic = false) => {
    setPromoError("");    
    const promoCodeToUse = isAutomatic ? promoCodeInput : promoCodeInput.trim();

    if (!promoCodeToUse) {
      setPromoError("Please input a promo code before proceeding");
      return;
    }

    if (!isAutomatic && promoApplied) {
      setPromoError("Promo code already applied");
      return;
    }

    const promoData = {
      rcode: promoCodeToUse,
      eventid: eventID,
    };

    fetch(`${SERVER_HOST}${API_URL}${REFERALL_CODE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(promoData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok.");
        }
        return res.json();
      })
      .then((response) => {
        let data = response.data;
        let result_code = response.result_code;
        let message = response.message;

        if (result_code === 1) {
          toast.error(message, {
            position: "top-right",
            duration: 4000,
            className: "mt-24 ",
          });
          setPromoApplied(false);
        }

        if (result_code === 0) {
          toast.success("Successfully applied Promo Code", {
            position: "top-right",
            duration: 4000,
            className: "mt-24 ",
          });
          setPromoApplied(true);
          setPromoCode(promoCodeToUse);
          const dxRef = (100 - data.discount_percentage) / 100;
          let totalCost = totalTicketPrice * dxRef;

          setTotalTicketPrice(Math.round(totalCost));

          if (data.discount_percentage > 0) {
            setPromoMessage(
              `You've applied a discount of ${data.discount_percentage}%.`
            );
          } else {
            setPromoMessage("");
          }
        }
      })
      .catch((error) => {
        console.error("Error applying promo code:", error);      
      });
  }; 

  
  const handleGroupTicketAttendeeInput = (
    ticketIndex,
    attendeeIndex,
    type,
    value
  ) => {
    handleSingleGroupInputChange(
      children,
      setChildren,
      ticketIndex,
      attendeeIndex,
      type,
      value
    );
  };

  const getConcatenatedNames = (children) => {
    return children.map(child => `${child.firstName} ${child.lastName}`);
  };


  const buySinglePaidTickets = (e) => {
    setError("");
    e.preventDefault();
    e.stopPropagation();

    if (!selectedImage) {
      setError("Select Payment Method!!");
      return;
    }

    if (selectedImage && selectedImage === "mpesa") {
      const userData = {
        eventid: eventID,
        eventname: eventName,
        ttype: [`${ticketType}`],
        tid: [`${ticketID}`],
        tickettypeid: [`${ticketTypeID}`],
        tnumber: [`${ticketCount}`],
        tprice: [`${totalTicketPrice}`],
        tseats: null,
        tuserid: "user_1",
        tamount: totalTicketPrice,
        email: email,
        name: `${firstName} ${lastName}`,
        number: phoneNumber,
        pay_number: mpesaNumber,
        occupation: position ? position : "Not Set",
        postaladdress: postalAddress ? postalAddress : "Not Set",
        physicaladdress: physicalAddress ? physicalAddress : "Not Set",
        secondname: lastName,
        officephonenumber: officeNumber ? officeNumber : "Not Set",
        gender: gender ? gender : "Not Set",
        age: age? age : "Not Set",
        treferall: promoCode ? promoCode : "Not Set",
        // children: ticketCount > 1 ? [repeatFullName(ticketCount)] : [fullName],
        children: ticketMode === 'group' ? getConcatenatedNames(children) : ticketCount > 1 ? [repeatFullName(ticketCount)] : [fullName],
        tinsured: false,
        pay_via: selectedImage,
        order_id: "G269208",
        company: company ? company : "Not Set",
        currency: currency,
      };
      confirmInfo(userData);   
    }

    if (selectedImage && selectedImage === "visa") {
      const transactionData = {
        ticketeventid: eventID,
        ticketnumberoftickets: ticketCount,
        tickettype: ticketType,
        tickettypeid: ticketTypeID,
        ticketprice: totalTicketPrice,
        ticketmode: "individual",
        ticketmodenumber: 1,
        ticketchildren: ticketMode === 'group' ? getConcatenatedNames(children) : ticketCount > 1 ? [repeatFullName(ticketCount)] : [fullName],
        ticketuseremail: email,
        ticketusername: fullName,
        ticketusercompany: company ? company : "Not Set",
        ticketuseroccupation: position ? position : "Not Set",
        ticketusergender: gender ? gender : "Not Set",
        ticketuserage: age ? age : "Not Set",
        ticketinitiator: "web",
        tickettransactionreftype: "CARD",
        ticketinsured: false,
        ticketreferall: promoCode ? promoCode : "Gig", //not sure ask later
      };

      const userData = {
        transactionId: generateRandomString(),
        userNumber: phoneNumber,
        ticketCost: totalTicketPrice,
        transactionRefType: "CARD",
        transactionRefNumber: "pending",
        eventId: eventID,
        transactionStatus: "pending",
        transactionType: "card",
        transactionFor: "ticket",
        transactionData: transactionData,
        transactionDateTime: getCurrentDate() + " " + getCurrentTime(),
        transactionDate: getCurrentDate(),
        transactionTime: getCurrentTime(),
        transactionReferallCode: promoCode ? promoCode : "Gig",
        ticketnumber: ticketCount,
        ticketDescription: `Buy ${ticketCount} ${ticketType} Ticket${
          ticketCount > 1 ? "s" : ""
        } for the ${eventName} event.`,
        currency: currency,
      };
      confirmPaymentPesapal(userData);
      
    }
  };

  return {
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    age,
    setAge,
    company,
    setCompany,
    position,
    setPosition,
    postalAddress,
    setPostalAddress,
    officeNumber,
    setOfficeNumber,
    physicalAddress,
    setPhysicalAddress,
    gender,
    setGender,
    promoCode,
    setPromoCode,
    showInput,
    selectedImage,
    showMpesaInput,
    error,
    mpesaNumber,
    setMpesaNumber,
    promoError,
    promoMessage,
    promoApplied,
    ticketCount,
    ticketType,
    eventName,
    currency,
    dataNeeded,
    ticketMode,
    ticketModeNumber,
    totalTicketPrice,
    showHidePromoField,
    mpesaVisaSelection,
    checkPromoCode,
    buySinglePaidTickets,
    handleGroupTicketAttendeeInput
  };
};
