import React from 'react'

function PromoCodeInput({promoCode, setPromoCode, promoApplied}) {
  return (
    <input
                    type="text"
                    id="promo_code"
                    className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"                  
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    disabled={promoApplied}
                  />
  )
}

export default PromoCodeInput