import React from 'react';

const SelectInput = ({ label, id, value, onChange, options, required = false }) => (
  <div className="flex flex-col">
    <label htmlFor={id} className="text-primaryBlack font-roboto text-sm lg:text-base font-normal leading-6">
      {label}{required && <span className="text-secondaryRed">*</span>}
    </label>
    <select
      required={required}
      id={id}
      value={value}
      onChange={onChange}
      className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
);

export default SelectInput;
