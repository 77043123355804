import React from "react";
import { Helmet } from "react-helmet";

function EventMetaTags({ event }) {
  if (
    !event ||
    !event.eventname ||
    !event.eventdescription ||
    !event.eventid ||
    !event.eventcategory
  ) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>
          {event?.eventname ? `${event?.eventname} | Details` : "Event Details"}
        </title>        
        {/* <meta
          property="og:description"
          content={`${event?.eventdescription} | Event Tickets in Kenya and Nairobi`}
        />
        <meta
          property="og:title"
          content={`${event.eventname} | ${event.eventcategory}`}
        />
        <meta
          property="og:url"
          content={`https://gig.zynamis.co.ke/event/${event.eventid}/ticket`}
        />
        <meta
          property="og:image"
          content={`https://client.gig.co.ke/eventpics/${event.eventid}_banner_pic`}
        />
          <meta property="og:type" content="article" /> */}
      </Helmet>
    </>
  );
}

export default EventMetaTags;
