import Colors from "./Colors";

export const isStagingEnvironment = () =>
  window.location.host.includes("client");

export const isLocalEnvironment = () =>
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  window.location.host.includes("dev");

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const capitalize = (sentence) => {
  if (typeof sentence !== "string" || !sentence.trim()) return "";

  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const addEllipsisAfter40Words = (text) => {
  // Split the text into words
  const words = text.split(" ");

  if (words.length <= 40) {
    return text;
  }

  const truncatedText = words.slice(0, 60).join(" ");
  return truncatedText + "...";
};

export const formattedDate = (newDate) => {
  const date = new Date(newDate);

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const weekdayIndex = date.getDay();

  const weekdayAbbreviation = weekdays[weekdayIndex];

  const monthName = date.toLocaleString("en-US", { month: "short" });

  const dayOfMonth = date.getDate();

  const getOrdinalSuffix = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const formattedDayOfMonth = getOrdinalSuffix(dayOfMonth);

  const formattedDate = `${weekdayAbbreviation}, ${monthName} ${formattedDayOfMonth}, ${date.getFullYear()}`;

  return formattedDate;
};

export const generateRandomString = () => {
  const length = 12;
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
};

export const getCurrentTime = () => {
  const d = new Date();
  const hours = d.getHours() % 12 || 12; // Show midnight & noon as 12
  const minutes = String(d.getMinutes()).padStart(2, "0"); // Ensure two-digit minutes
  const period = d.getHours() < 12 ? "AM" : "PM"; // Determine AM/PM

  return `${hours}:${minutes} ${period}`;
};

export const ticketColours = [
  Colors.pale_blue,
  Colors.lavendar_blue,
  Colors.light_peach,
  Colors.pastel_green,
  Colors.pale_yellow,
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * ticketColours.length);
  return ticketColours[randomIndex];
};

export const setMetaTag = (tags) => {
  tags.forEach(({ property, content }) => {
    let metaTag = document.querySelector(`meta[property="${property}"]`);
    if (metaTag) {
      metaTag.setAttribute('content', content); // Update existing meta tag
    } else {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('property', property);
      metaTag.setAttribute('content', content);
      document.head.appendChild(metaTag); // Create new meta tag
    }
  });
};
