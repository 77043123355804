import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function CardSkeleton() {
  return (
    <div className="w-[340px] sm:w-60 md:w-[220px] lg:w-[274px] h-[450px] sm:h-[270px] md:h-[320px] lg:h-[440px] mb-4 ml-2 mr-2 overflow-hidden flex-shrink-0 bg-white border border-gray-200 rounded-lg  dark:border-gray-200 transition duration-500 hover:shadow-2xl shadow-md">
      <div className="border border-gray-200 dark:border-gray-20 animate-pulse">
        <div className="h-[320px] sm:h-[140px] md:h-[180px] lg:h-[300px] w-full sm:w-60 md:w-[220px] lg:w-[274px] rounded-t-lg object-fill animate-pulse" />
      </div>

      <div className="p-2 flex flex-col space-y-1">
        <div className="float-left text-base text-gray-900 w-85 ">
          <div className="pl-1">
            <div className="h-6 bg-gray-200 mb-2 animate-pulse rounded-lg" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-row space-x-3 pl-2">
            <span className="animate-pulse">
              <FontAwesomeIcon
                icon={faCalendarDays}
                className="text-silverGray"
              />
            </span>
            <span className="h-4 p bg-gray-200 w-16 animate-pulse rounded-lg"></span>
            <div />
          </div>
        </div>
        <div className="">
          <div className="flex flex-row space-x-3 pl-2">
            <span className="animate-pulse">
              <FontAwesomeIcon icon={faClock} className="text-silverGray" />
            </span>
            <span className="h-4 p bg-gray-200 w-16 animate-pulse rounded-lg"></span>
            <div />
          </div>
        </div>
        <div className="">
          <div className="flex flex-row space-x-3 pl-2">
            <span className="animate-pulse">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-silverGray"
              />
            </span>
            <span className="h-4 p bg-gray-200 w-16 animate-pulse rounded-lg"></span>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSkeleton;
