import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ticketColours } from "../util/Util";

function PaidAttendeeForm({
  open,
  ticketTypeArray,
  addTicketRecepient,
  handlePaidTicketAttendeeInput,
  removeTicketRecepient,
}) {
  return (
    <>
      <div>
        {ticketTypeArray.map((ticket, ticketIndex) => (
          <div key={ticket.ticketTypeID} className="flex flex-col gap-4">
            <div className="flex flex-row gap-3">
              <p
                className="flex flex-row items-center justify-center h-10  w-1/2 rounded-lg"
                style={{
                  backgroundColor:
                    ticketColours[ticketIndex % ticketColours.length],
                }}
              >
                {ticket.ticketMode === "group"
                  ? ticket.totalCount / ticket.ticketModeNumber
                  : ticket.totalCount}{" "}
                {ticket.ticketType} Ticket
                {(ticket.ticketMode === "group"
                  ? ticket.totalCount / ticket.ticketModeNumber
                  : ticket.totalCount) > 1
                  ? "s"
                  : ""}
              </p>
            {ticket.ticketMode === "individual" &&  <div
                className="flex flex-row space-x-2 items-center justify-center h-10 w-[100px] rounded-lg cursor-pointer"
                onClick={() => addTicketRecepient(ticketIndex)}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="text-primaryBlack"
                  size="xs"
                />
                <p className="font-roboto text-sm md:text-base font-normal leading-7 text-primaryBlack">
                  Add Ticket
                </p>
              </div>}
            </div>

            <div className="grid gap-6 mb-6 grid-cols-2 p-2">
              {[...Array(ticket.totalCount * 2)].map((_, attendeeIndex) =>
                attendeeIndex % 2 === 0 ? (
                  <div key={attendeeIndex} className="flex flex-col ">
                    <label
                      htmlFor={`first_name_${ticket.ticketTypeID}_${
                        attendeeIndex / 2
                      }`}
                      className="text-secondaryBlack font-roboto text-base font-regular leading-6"
                    >
                      First Name
                      <span className="text-secondaryRed">*</span>
                    </label>
                    <input
                      required={open}
                      type="text"
                      id={`first_name_${ticket.ticketTypeID}_${
                        attendeeIndex / 2
                      }`}
                      className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"
                      placeholder="John"
                      onChange={(e) =>
                        handlePaidTicketAttendeeInput(
                          ticketIndex,
                          attendeeIndex / 2,
                          "firstName",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : (
                  <div className="flex flex-col ">
                    <div
                      key={attendeeIndex}
                      className="flex flex-row w-full gap-3"
                    >
                      <div className="flex flex-col w-full">
                        <label
                          htmlFor={`last_name_${ticket.ticketTypeID}_${
                            (attendeeIndex - 1) / 2
                          }`}
                          className="text-secondaryBlack font-roboto text-base font-regular leading-6"
                        >
                          Last Name
                          <span className="text-secondaryRed">*</span>
                        </label>
                        <input
                          required={open}
                          type="text"
                          id={`last_name_${ticket.ticketTypeID}_${
                            (attendeeIndex - 1) / 2
                          }`}
                          className="border border-primaryBlack text-primaryBlack text-sm font-roboto rounded-lg w-full p-2.5 block"
                          placeholder="Doe"
                          onChange={(e) =>
                            handlePaidTicketAttendeeInput(
                              ticketIndex,
                              (attendeeIndex - 1) / 2,
                              "lastName",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      {ticket.ticketMode === "individual" &&
                        (attendeeIndex - 1) / 2 !== 0 && (
                          <div
                            className=" right-0 mt-8"
                            onClick={() => removeTicketRecepient(ticketIndex)}
                          >
                            <FontAwesomeIcon
                              icon={faX}
                              className="text-primaryBlack"
                              size="sm"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default PaidAttendeeForm;
