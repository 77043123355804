import React from "react";
import { useNavigate } from "react-router-dom";

function CarouselButton({ event }) {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`/event/${event.eventid}/ticket`)}
      className="bg-customOrange text-white hover:text-white font-medium lg:py-2 px-6 rounded-lg text-sm md:text-base hover:bg-orangeBrown w-72 h-8 md:h-[40px] md:w-[160px] "
    >
      {event.eventpaying === "true" ? "Get Tickets" : "RSVP"}
    </button>
  );
}

export default CarouselButton;
