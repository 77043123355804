import React from 'react'

function TotalAmountHandler({event, totalAmount}) {
  return (
   <>
    {event && event.eventpaying === "true" ? (
                        <div className="bg-peachyBeige h-12 rounded-lg flex flex-row w-full">
                          <p className="flex-grow text-primaryBlue font-roboto text-[19px] font-medium leading-6 p-3">
                            Total Amount
                          </p>
                          <p className="p-3 text-primaryBlue font-roboto text-[19px] font-medium leading-6">
                            {event.currency} {totalAmount.toLocaleString()}
                          </p>
                        </div>
                      ) : (
                        <div className=" h-12 rounded-lg flex flex-row w-full"></div>
                      )}
   </>
  )
}

export default TotalAmountHandler