import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EventInfo = ({ icon, text, formatter }) => {
  return (
    <div className="flex flex-row gap-5">
      <span>
        <FontAwesomeIcon icon={icon} size="xl" className="text-customGrey" />
      </span>
      <span className="font-roboto md:text-base text-[14px] font-medium leading-relaxed text-primaryBlue">
        {formatter ? formatter(text) : text}
      </span>
    </div>
  );
};

export default EventInfo;
