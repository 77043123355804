import  { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EventRedirect = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    navigate(`/event/${id}/ticket`);
  }, [navigate, id]);

  return null;
};

export default EventRedirect;
