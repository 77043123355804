import React from 'react';

const PaymentButton = ({ label, onClick }) => (
  <div className="p-3">
    <button
      className="bg-customOrange hover:bg-orangeBrown text-white hover:text-white font-bold p-2 rounded-lg text-base w-full"
      type="submit"     
    >
      {label}
    </button>
  </div>
);

export default PaymentButton;
