import React, { useState } from "react";
import logo from "../../images/gig_logo 1.png";
import { NavLink, useNavigate } from "react-router-dom";
import google_logo from "../../images/Google_logo.svg.png";
import Endpoints from "../../util/Endpoints";
import Strings from "../../util/Strings";
import { Spinner } from "@material-tailwind/react";
import { useAuth } from "../../hooks/auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { loginUser } = useAuth();

  const handleLogin = (e) => {
    e.preventDefault();
    setError("");

    if (!email || !password) {
      setError("All fields are required");
      return;
    }

    setIsLoading(true);

    const data = {
      email,
      password,
    };

    const BASE_URL = Endpoints.SERVER_HOST + Endpoints.API_URL;

    fetch(BASE_URL + Endpoints.LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((res) => {
        setIsLoading(false);
        if (!res) {
          setError(
            "Invalid response from server, try again or contact admin for assistance"
          ); //this occurs when OTP generation fails
          return;
        }
        const result = res.data;

        if (!result) {
          setError(res.message);
          return;
        }       

        loginUser(
          JSON.stringify({ ...result, token: "" }),
          result.token,
          () => {
            setMessage("Successfully logged in");
            setTimeout(() =>  navigate("/"), 1500);
          }
        );
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.message || Strings.CONNECTION_ERROR_MESSAGE);
      });
  };

  return (
    <>
      <div className="container mx-auto relative max-w-[1520px] max-h-5/6">
        <div className="mx-auto w-full grid grid-cols-2">
          <div className="col-span-1 h-screen ">
            <div className="flex flex-col gap-4 justify-center h-full p-40">
              <img className="h-24 w-16" src={logo} alt="Gig Logo" />
              <h5 className="font-roboto font-bold text-2xl text-loginBlue leading-8 text-left">
                Log in to your Account
              </h5>
              <div className="flex flex-col gap-4 ">
                <h2 className="font-roboto font-normal text-base leading-6 text-left text-darkCyan">
                  Welcome back! Select method to log in:
                </h2>

                <button
                  className="flex items-center gap-4 justify-center bg-whiteSmoke text-cadetGrey font-roboto font-medium text-base leading-6 py-2 px-6 rounded-lg w-[400px] cursor-pointer shadow-md "
                  type="submit"
                >
                  <img
                    className="w-[24px]"
                    src={google_logo}
                    alt="Google Logo"
                  />{" "}
                  Login with Google
                </button>
              </div>

              <div className="flex items-center">
                <hr className="bg-primaryBlack w-[120px] border-b-1 border-primaryBlack m-0" />
                <span className="text-steelBlue mx-2 font-roboto text-sm font-normal leading-20 text-left">
                  or continue with Username
                </span>
                <hr className="bg-primaryBlack w-[100px] border-b-1 border-primaryBlack m-0" />
              </div>
              {error || message ? (
                <div
                  className={`${error ? "bg-mistyRose" : "bg-aquaIsland"} border ${
                    error ? "border-pinkLace" : "border-paleAqua"
                  } border-solid items-center flex justify-center p-2 h-10 w-[400px] rounded-lg`}
                >
                  <p
                    className={`font-roboto text-md font-normal leading-[150%] ${
                      error ? "text-rosewood" : "text-deepJungleGreen"
                    }`}
                  >
                    {error || message}
                  </p>
                </div>
              ) : null}
              <form onSubmit={(e) => handleLogin(e)}>
                <div className="grid gap-6 mb-6 md:grid-rows-2">
                  <div className="flex flex-col ">
                    <label
                      htmlFor="email"
                      className="text-loginBlue font-roboto text-base font-medium leading-6"
                    >
                      Email*
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="items-center justify-center border border-cadetGrey text-primaryBlack text-sm p-2.5 font-roboto rounded-lg w-[400px]"
                      placeholder="ricky@gmail.com"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="password"
                      className="text-loginBlue font-roboto text-base font-medium leading-6"
                    >
                      Password*
                    </label>
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="items-center justify-center border border-cadetGrey text-primaryBlack text-sm p-2.5 font-roboto rounded-lg w-[400px]"
                      placeholder="Ricky@1234"
                    />
                  </div>
                  <button
                    className="bg-darkNavyBlue text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base w-[400px] cursor-pointer hover:bg-blueGray flex items-center justify-center"
                    type="submit"
                  >
                    {isLoading ? <Spinner color="blue" /> : "Login"}
                  </button>
                </div>
              </form>

              {/* <NavLink to="/forgot-passsword">
                <h3 className="font-roboto font-semibold text-base leading-6 text-darkNavyBlue cursor-pointer">
                  Forgot Password?
                </h3>
              </NavLink> */}
            </div>
          </div>
          <div className="col-span-1 bg-darkBlue h-screen rounded-tl-[64px]">
            <div className="flex flex-col gap-4 items-center justify-center h-full">
              <h4 className="font-roboto text-[28px] font-bold leading-10 text-center text-white">
                Hello Friend. Nice to <br /> have you here!
              </h4>
              <p className="font-roboto text-base font-normal leading-6 text-center text-white">
                Create an account with us to start creating events.
              </p>
              <button className="border border-white text-white hover:bg-white hover:border-transparent hover:text-darkBlue font-bold py-2 px-4 rounded-lg">
                <NavLink to="/signup">Create an Account</NavLink>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
