import React from "react";
import CardSkeleton from "./CardSkeleton";
import { v4 as uuidv4 } from "uuid";
import EventCard from "./EventCard";

function SearchedEvents({ searchedEvents, isLoading, search }) {
  const eventList = searchedEvents.map((event, index) => (
    <EventCard key={index} event={event} />
  ));
  return (
    <>
      <div className="relative w-screen mt-4 lg:max-w-7xl mx-auto  gap-2 pb-[40px] justify-center">
        <div>
          <p className="ml-4">Showing results for "{search}"</p>
        </div>
        <div className="relative w-screen lg:max-w-7xl mx-auto justify-center flex flex-col gap-6 pb-[40px]">
          {isLoading ? (
            <div className="flex flex-col mx-auto justify-center sm:grid sm:grid-cols-2 mt-4 sm:ml-3 sm:mr-3 md:grid-cols-3 xl:grid-cols-4 gap-2">
              {Array.from({ length: 25 }, (_, index) => (
                <div
                  className={`skeleton-wrapper`}
                  key={`card-${index}-${uuidv4()}`}
                >
                  <CardSkeleton />
                </div>
              ))}
            </div>
          ) : (
            <div
              className={`event-list ${
                isLoading ? "event-list-hidden" : "event-list-visible"
              } flex flex-col mx-auto justify-center  sm:grid sm:grid-cols-2 p-3 sm:ml-[-2px] sm:mr-3 lg:grid-cols-3 xl:grid-cols-4`}
            >
              {eventList}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchedEvents;
