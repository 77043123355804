export const calculateTotalAmount = (ticketData) => {
  let totalAmount = 0;
  Object.values(ticketData).forEach((ticketInfo) => {
    totalAmount += ticketInfo.totalPrice || 0;
  });
  return totalAmount;
};

export const calculateTotalCount = (ticketData) => {
  let count = 0;
  Object.values(ticketData).forEach((ticketInfo) => {
    count += ticketInfo.ticketCount || 0;
  });
  return count;
};

export const updateTicketData = (
  type,
  count,
  amount,
  eventID,
  ticketID,
  ticketTypeID,
  ticketMode,
  ticketModeNumber,
  ticketData,
  setTicketData
) => {
  const updatedData = { ...ticketData };
  let totalPrice = count * amount;
  if (ticketMode === 'group') {
    totalPrice = count * amount * ticketModeNumber;
  }
  if (count === 0 && updatedData[type]) {
    delete updatedData[type];
  } else {
    updatedData[type] = {
      ticketType: type,
      ticketCount: ticketMode === 'group' ? ticketModeNumber * count : count,
      ticketAmount: amount,
      totalPrice: totalPrice,
      eventID: eventID,
      ticketTypeID: ticketTypeID,
      ticketID: ticketID,
      ticketMode: ticketMode,
      ticketModeNumber: ticketModeNumber
    };
  }
  setTicketData(updatedData);
};

export const getTotalCountByType = (ticketData, type) => {
  let totalCount = 0;
  if (ticketData[type]) {
    totalCount = ticketData[type].ticketCount;
  }
  return totalCount;
};

export const getTicketTypeCountArray = (
  refValue,
  ticketData,
  eventName,
  currency,
  dataNeeded
) => {
  return Object.keys(ticketData).map((ticketType) => ({
    refValue,
    dataNeeded,
    eventName,
    currency,
    ticketType,
    totalCount: getTotalCountByType(ticketData, ticketType),
    ticketPrice:
      getTotalCountByType(ticketData, ticketType) *
      ticketData[ticketType].ticketAmount,
    totalPrice: calculateTotalAmount(ticketData),
    eventID: ticketData[ticketType].eventID,
    ticketTypeID: ticketData[ticketType].ticketTypeID,
    ticketID: ticketData[ticketType].ticketID,
    ticketMode: ticketData[ticketType].ticketMode,
    ticketModeNumber: ticketData[ticketType].ticketModeNumber
  }));
};
